import exchangeApi from '../../api/exchange-api';
const EXCHANGE_RATE_WIDGET_TECHNICAL_FAILURE = 'exchangeRateWidgetTechnicalFailure';
const EXCHANGE_RATE_WIDGET_GET_RATE_REQUEST = 'exchangeRateWidgetGetRateRequest';
const EXCHANGE_RATE_WIDGET_GET_RATE_SUCCESS = 'exchangeRateWidgetGetRateSuccess';

const exchangeRateWidgetState = {
	loading: false,
	technicalError: false,
	results: null
};

const exchangeRateWidgetActions = {
	getRate({ commit }) {
		commit(EXCHANGE_RATE_WIDGET_GET_RATE_REQUEST);
		exchangeApi
			.getWidgetRate()
			.then((response) => {
				commit(EXCHANGE_RATE_WIDGET_GET_RATE_SUCCESS, response.data);
			})
			.catch(() => {
				commit(EXCHANGE_RATE_WIDGET_TECHNICAL_FAILURE);
			});
	}
};

const exchangeRateWidgetMutations = {
	[EXCHANGE_RATE_WIDGET_TECHNICAL_FAILURE](state) {
		state.technicalError = true;
		state.loading = false;
	},
	[EXCHANGE_RATE_WIDGET_GET_RATE_SUCCESS](state, results) {
		state.results = results;
		state.loading = false;
	},
	[EXCHANGE_RATE_WIDGET_GET_RATE_REQUEST](state) {
		state.loading = true;
	}
};

export default {
	namespaced: true,
	state: exchangeRateWidgetState,
	mutations: exchangeRateWidgetMutations,
	actions: exchangeRateWidgetActions
};

import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import messages from './exchange-rate-widget-lang.json';
import VueGeolocation from 'vue-browser-geolocation';
import Vue from 'vue';
import { DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT } from '../../common/js/constants/constants';
Vue.use(VueGeolocation);
export default {
	data() {
		return {
			branchOrATM: null
		};
	},
	props: {
		detailsUrl: {
			required: true
		},
		type: {
			required: true
		}
	},
	i18n: { messages },
	validations() {
		return {};
	},
	watch: {
		results(value) {
			if (value) {
				this.$nextTick(() => {
					this.$refs['exchange-rate-widget-section'].classList.remove('content-placeholder-active');
				});
			}
		}
	},
	computed: {
		date() {
			let formattedDate;
			if (this.results) {
				let date = moment(this.results.validity);
				formattedDate = date.format(`${DISPLAY_DATE_FORMAT[this.$i18n.locale]}/${this.results.version}. ${DISPLAY_TIME_FORMAT[this.$i18n.locale]}`);
			}
			return formattedDate;
		},
		...mapState('exchangeRateWidget', ['technicalError', 'loading', 'results'])
	},
	methods: {
		...mapActions('exchangeRateWidget', ['getRate'])
	},
	created() {
		this.getRate();
	}
};
